<template>
  <div :is="accountData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="accountData === undefined">
      <h4 class="alert-heading">{{ $t("message.errorLoadingData") }}</h4>
      <div class="alert-body">
        {{ $t("message.accounts.accountNotFound") }}
        <b-link class="alert-link" :to="{ name: 'apps-account-list' }">
          {{ $t("message.accounts.accountList") }}
        </b-link>
        {{ $t("message.accounts.forOtherAccounts") }}
      </div>
    </b-alert>

    <template v-if="accountData">
      <div>
        <b-card>
          <h2 class="mb-3">{{ $t("message.accounts.viewEdit") }}</h2>

          <b-row>
            <!-- text area -->
            <b-col md="12">
              <validation-provider name="title" rules="required">
                <b-form-group label-for="titleAccount">
                  <template v-slot:label>
                    {{ $t("message.accounts.desc") }}
                  </template>

                  <b-alert variant="secondary" show>
                    <div class="alert-body">
                      <span v-html="accountData.description"></span>
                    </div>
                  </b-alert>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group label-for="expiryDate">
                <template v-slot:label>
                  {{ $t("message.accounts.date") }}
                </template>
                <b-form-datepicker
                  v-model="accountData.date"
                  :locale="$i18n.locale"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="valor"
                rules="required"
              >
                <b-form-group label-for="descriptionContracts">
                  <template v-slot:label>
                    {{ $t("message.accounts.valor") }}
                  </template>
                  <b-form-input
                    disabled
                    v-money="getCurrencyFormat()"
                    v-model="accountData.value"
                    class="mt-0"
                    label-for="descriptionContracts"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group label-for="typeAccount">
                <template v-slot:label>
                  {{ $t("message.accounts.category") }}
                </template>
                <v-select
                  v-model="accountData.category"
                  :options="categoryOptions"
                  :reduce="(val) => val.value"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="typeAccount">
                <template v-slot:label>
                  {{ $t("message.accounts.bankAccount") }}
                </template>
                <v-select
                  v-model="accountData.bankAccount"
                  :options="bankList"
                  :reduce="(val) => val.value"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div class="text-right mt-2 mr-2 mb-2">
                <b-button
                  v-if="restrictions('button_edit_account')"
                  :to="{
                    name: 'apps-accounts-edit',
                    params: { id: accountData.id },
                  }"
                  variant="primary"
                >
                  {{ $t("message.buttons.edit") }}
                </b-button>
                <b-button
                  v-if="restrictions('button_delete_account')"
                  v-b-modal.modal-delete-confirmation
                  variant="outline-danger"
                  class="ml-1"
                >
                  {{ $t("message.buttons.delete") }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <b-modal
        id="modal-delete-confirmation"
        modal-class="modal-danger"
        centered
        :title="$t('delete')"
        hide-footer
      >
        <b-card-text>
          {{ $t("message.deletAcount") }}
        </b-card-text>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <b-button
            v-b-modal.modal-danger
            class="mt-2"
            variant="danger"
            :to="{ name: 'apps-account-list' }"
            @click="deleteAccount()"
          >
            {{ $t("message.buttons.delete") }}
          </b-button>
        </div>
      </b-modal>
    </template>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BCardText,
  BTab,
  BCard,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BFormTextarea,
  BFormDatepicker,
} from "bootstrap-vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import router from "@/router";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import AccessControl from "@core/utils/access-control";
import store from "@/store";
import { VMoney } from "v-money";
import currency from "@/@core/utils/currency";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BCardText,
    BTab,
    BCard,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    BFormTextarea,
    vSelect,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },

  data() {
    return {
      userData: store.state.user.userData,
      flatConfig: { altFormat: "d/m/Y", altInput: true, format: "d/m/Y" },

      bankList: [],
      categoryOptions: [],
    };
  },

  directives: {
    money: VMoney,
  },

  setup() {
    const accountData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}account/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        accountData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          accountData.value = undefined;
        }
      });

    return {
      accountData,
    };
  },

  async created() {
    var bankList = [];
    var bankId = [];
    await axios
      .get(`${URL_API}bank/options`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          bankList.push(element);
          bankId.push(id);
          this.bankList.push({
            label: bankList[i],
            value: bankId[i],
          });
        }
      });

    var category = [];
    await axios
      .get(`${URL_API}account_categories`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].category;
          category.push(element);
          this.categoryOptions.push({
            label: category[i],
            value: category[i],
          });
        }
      });
  },

  methods: {
    getCurrencyFormat() {
      return currency(this.$i18n.locale);
    },

    restrictions(value) {
      return AccessControl(value);
    },

    deleteAccount() {
      axios({
        method: "delete",
        url: `${URL_API}account/${this.accountData.id}`,
        headers: getHeader(this.userData),
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.deleted"),
              icon: "CheckIcon",
              variant: "success",
              text: `A conta foi deletada com sucesso`,
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            accountData.value = undefined;
          }
        });
    },
  },
};
</script>

<style>
.mtn {
  margin-top: -0.8rem;
}
</style>
